import Axios from "axios";
import { store } from "@/redux/store";
import { setError } from "@/redux/reducers/ErrorReducer";
import { ERRORS } from "@/utils/constants";

let instance = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

export const reinitializeAxios = (sentToken?: string) => {
  console.log(sentToken);
  instance.defaults.headers.common["Authorization"] = "Bearer " + sentToken;
};

instance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    // Add or modify headers here
    const token = state.Auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      store.dispatch(setError(ERRORS.UNAUTHORIZED));
      return Promise.reject(new Error("Please Connect Wallet"));
    }
    return Promise.reject(error);
  },
);
export default instance;
