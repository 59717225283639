import { createSlice } from "@reduxjs/toolkit";
import { myRewardsType } from "@/types/global";

const initLoginState: myRewardsType = {
  pointsTotal: 0,
  pointsYesterday: 0,
  leaderboardRank: 0,
};
const rewardReducer = createSlice({
  name: "reward",
  initialState: initLoginState,
  reducers: {
    setRewards: (loginState, action) => {
      return action.payload;
    },
  },
});

export const { setRewards } = rewardReducer.actions;
// export default reducer;
export default rewardReducer.reducer;
