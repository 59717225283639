import { createSlice } from "@reduxjs/toolkit";
import { userType } from "@/types/global";

export interface initLoginState {
  token?: string;
  user?: userType;
  referralCode?: string;
}

const initLoginState: initLoginState = {
  token: undefined,
  user: undefined,
  referralCode: undefined,
};
const authReducer = createSlice({
  name: "auth",
  initialState: initLoginState,
  reducers: {
    login: (loginState, action) => {
      const { token, user } = action.payload;
      return {
        ...loginState,
        token,
        user,
      };
    },
    setUser: (loginState, action) => {
      const user = action.payload;
      return {
        ...loginState,
        user,
      };
    },
    logout: (loginState) => {
      return { ...loginState, token: undefined, user: undefined };
    },
    setReferralCode: (loginState, action) => {
      const code = action.payload;
      return {
        ...loginState,
        referralCode: code,
      };
    },
    resetReferralCode: (loginState, action) => {
      const code = action.payload;
      return {
        ...loginState,
        referralCode: undefined,
      };
    },
  },
});

export const { login, logout, setUser, setReferralCode, resetReferralCode } =
  authReducer.actions;
// export default reducer;
export default authReducer.reducer;
