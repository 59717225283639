import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import popUpsReducer from "@/redux/reducers/PopUpsReducer";
import authReducer from "@/redux/reducers/AuthReducer";
import ChartReducer from "@/redux/reducers/ChartReducer";
import RefreshReducer from "@/redux/reducers/RefreshReducer";
import RewardReducer from "@/redux/reducers/RewardReducer";
import { createLogger } from "redux-logger";
import ErrorReducer from "@/redux/reducers/ErrorReducer";
import ConfigReducer from "@/redux/reducers/ConfigReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["Auth"],
};
const logger = createLogger();
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    PopUps: popUpsReducer,
    Auth: authReducer,
    Refresh: RefreshReducer,
    Config: ConfigReducer,
    Error: ErrorReducer,
    Chart: ChartReducer,
    Reward: RewardReducer,
  }),
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
