// Profile Page-->>
export const LEFT_ARROW_ICON_BLUE =
    "/assets/icons/ProfilePage/left-arrow-blue-icon.svg",
  CALENDER_ICON_DARK = "/assets/icons/ProfilePage/calender-dark-icon.svg",
  SETTING_ICON = "/images/icons/setting.svg",
  GLOBE_ICON = "/assets/icons/ProfilePage/globe-icon.svg",
  LINK_EXTERNAL_ICON = "/assets/icons/ProfilePage/link-external-icon.svg",
  TELEGRAM_ICON = "/assets/icons/ProfilePage/telegram-icon.svg",
  X_ICON = "/assets/icons/ProfilePage/x-icon.svg",
  X_ICON_WHITE = "/assets/icons/ProfilePage/X-white.svg",
  CHEVRON_ICON_DOWN = "/assets/icons/ProfilePage/chevron-down.svg",
  BLACK_ICON_DOWN = "/assets/icons/ProfilePage/black-down.svg",
  CLOCK_ICON = "/images/icons/clock-check.svg",
  LIKE_IMG = "/images/icons/like.svg",
  COMMENT_IMG = "/images/icons/comment.svg",
  EDIT_IMG = "/images/icons/edit.svg",
  SHARE_IMG = "/images/icons/share.svg",
  THREE_DOT_ICON = "/assets/icons/ProfilePage/three-dot-icon.svg",
  REPORT_ICON = "/assets/icons/ProfilePage/report-icon.svg",
  SHARE_ICON = "/assets/icons/ProfilePage/share-icon.svg",
  BLOCK_ICON = "/assets/icons/ProfilePage/block-icon.svg",
  PENCIL_ICON = "/assets/icons/ProfilePage/pencil-icon.svg",
  NO_COIN_HELD_ICON = "/assets/icons/ProfilePage/no-coin-held.svg",
  BITCOIN_IMG = "/images/bitcoin.jpeg",
  CELEBRATION = "/images/celebration.svg",
  CELEBRATION_BACKGROUND = "/images/CelebBack.svg",
  INFO_ICON = "/images/icons/info.svg",
  COPY_ICON = "/images/icons/copy.svg",
  FOMO_CLOCK = "/assets/icons/Clock.png",
  NO_MEMES_ICON = "/assets/icons/folder.png",
  NO_ROCKETS_ICON = "/assets/icons/rocket.png",
  FOMO_BOT = "/assets/icons/FOMObot.svg",
  CONGRATS = "/images/congrats.svg";

export const Images = {
  IC_EMPTY_IMAGE: "/assets/icons/empty-image.svg",
  SMILE_GLASSES: "/assets/icons/smile-glasses.svg",
  COVER_IMG: "/images/coverImg.png",
  IC_UPLOAD: "/assets/icons/add-icon.svg",
  iconCross: "/assets/icons/cross.svg",
  ETH_ICON: "/assets/icons/Eth.png",
  PRICE_DOWN: "/assets/icons/price-down.svg",
  PRICE_UP: "/assets/icons/price-up.svg",
  FOMO_CLICK_LOGO: process.env.NEXT_PUBLIC_SITE_LOGO,
  FOMO_CLICK_IMG: process.env.NEXT_PUBLIC_SITE_IMG,
  BASE_LOGO: "/assets/icons/baseLogo.svg",
  WALLET: "/assets/icons/wallet.svg",
  PLUS_CIRCLE: "/assets/icons/plus-circle.svg",
  XP_COIN: "/assets/icons/xp_coin.svg",
  TASK: "/assets/icons/task.svg",
  PODIUM: "/assets/icons/Podium.svg",
  TAX_ICON: "/assets/icons/tax.svg",
  CROWN1: "/assets/icons/Rank.svg",
  CROWN2: "/assets/icons/Rank2.svg",
  CROWN3: "/assets/icons/Rank3.svg",
  BAR_CHART: "/assets/icons/bar-chart.svg",
  BOX: "/assets/icons/box.svg",
  COINS_SWAP: "/assets/icons/coins-swap.svg",
  USERS: "/assets/icons/users.svg",
};
export const GIFS = {
  TOKEN_CREATED: "/gifs/token_created.gif",
};

// Delete Post Popup ----->>

export const LEFT_ARROW_DATK =
    "/assets/icons/DeletePostPopup/left-arrow-dark-icon.svg",
  DELETE_ICON = "/assets/icons/DeletePostPopup/delete-icon.png";

// EmptyTabesPlaceholder----->>
export const NO_COIN_ICON =
    "/assets/icons/EmptyTabesPlaceholder/no-coin-icon.svg",
  NO_FOLLOWERS_ICON =
    "/assets/icons/EmptyTabesPlaceholder/no-followers-icon.svg";

// dashboard ---->>
export const FILTER_ICON = "/assets/icons/filter-icon.svg";
