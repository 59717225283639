import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { configResponseType } from "@/types/global";
import { getConfig } from "@/network/auth";

export interface initLoginState {
  config?: configResponseType;
  SITE_NAME: string;
  SITE_KEY: string;
}

const initLoginState: initLoginState = {
  config: undefined,
  SITE_NAME: process.env["NEXT_PUBLIC_SITE_NAME"] ?? "",
  SITE_KEY: process.env["NEXT_PUBLIC_SITE_KEY"] ?? "",
};

const authReducer = createSlice({
  name: "config",
  initialState: initLoginState,
  reducers: {
    setConfig: (state, action) => {
      const config = action.payload;
      return {
        ...state,
        config,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    // builder.addCase(getContractsConfig.fulfilled, (state, action) => {
    // state.config = action.payload;
    // });
  },
});
// export default reducer;
export const { setConfig } = authReducer.actions;
export default authReducer.reducer;
