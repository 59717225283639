import { createSlice } from "@reduxjs/toolkit";

export interface initLoginState {
  error: string | undefined;
}

const initLoginState: initLoginState = {
  error: undefined,
};
const errorReducer = createSlice({
  name: "error",
  initialState: initLoginState,
  reducers: {
    setError: (loginState, state) => {
      return {
        ...loginState,
        error: state.payload,
      };
    },
    resetError: (loginState) => {
      return {
        ...loginState,
        error: undefined,
      };
    },
  },
});

export const { setError, resetError } = errorReducer.actions;
// export default reducer;
export default errorReducer.reducer;
