import {
  AxiosError,
  commentReplyType,
  commentType,
  tokenType,
} from "@/types/global";
import { toast } from "react-toastify";
import exp from "node:constants";
import { ethers } from "ethers";

export function maskWalletAddress(
  address: `0x${string}` | undefined | string,
  startLength = 4,
  endLength = 4,
) {
  if (!address) {
    return "";
  }

  const totalLength = address.length;
  const middleLength = totalLength - startLength - endLength;
  const maskedMiddle = "...".repeat(Math.ceil(middleLength / 3));

  return (
    address.slice(0, startLength) +
    "..." +
    address.slice(totalLength - endLength)
  );
}

export function truncateString(str: string, length: number) {
  if (str.length <= length) return str;
  return str.slice(0, length - 3) + "...";
}

export function clampPercentage(
  num: number,
  lower: number = 0,
  upper: number = 100,
) {
  return Math.min(Math.max(num, lower), upper);
}

export const getBondingCurveEth = (token: tokenType) => {
  const tokens = parseFloat(
    ethers.utils.formatEther(token?.reserves?.eth ?? "0"),
  );
  return ConvertToCustomSubscriptFormat(tokens);
};

export const BONDING_CURVE_VALUE = 12.25;
export const getRocketsProgress = (cap: number, token?: tokenType): number => {
  if (!!token?.meta?.tradingOnUniswap) return 100;
  const rockets = 6;
  return clampPercentage(toFixed(+(((cap - 1) / (rockets - 1)) * 100)));
};

export function toFixed(num: number | string, fixed: number = 2) {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  const matchValue = num.toString()?.match(re);
  return parseFloat(matchValue && matchValue.length ? matchValue[0] : "0");
}
export const getBondingCurveProgress = (
  cap: number,
  token?: tokenType,
): number => {
  if (!!token?.meta?.tradingOnUniswap) return 100;
  return clampPercentage(
    toFixed(+(((cap - 1) / (BONDING_CURVE_VALUE - 1)) * 100)),
  );
};

export function calculateEthPrice(amount: number, price: number) {
  return (amount * price).toFixed(2);
}

export function calculateEthAmount(amount: number, price: number) {
  return (amount / price).toFixed(2);
}

export function formatDecimals(number: string | number, digits = 5) {
  return parseFloat(number.toString()).toFixed(digits);
}

export const getChangeData = (token: tokenType) => {
  const changePercentage = `(${formatDecimals(token.profitPercentage ?? "0", 2)}%)`;
  const changeValue = ` ${parseFloat(token.profitUsd) < 0 ? "-" : "+"}$${token.profitUsd ?? "0"}`;
  return changeValue + changePercentage;
};
export function subscriptSmallDecimals(
  balance?: number | string,
  significantDigits = 4,
  subscriptStart = 4,
) {
  if (!balance) return "0";
  // Convert the balance to a string
  const balanceStr = balance.toString();

  // Split balance into integer and decimal parts
  const [integerPart, decimalPart] = balanceStr.split(".");
  if (!decimalPart || decimalPart.length <= subscriptStart) {
    return balanceStr; // No need to subscript if decimal part is too short
  }

  // Format the balance with significant digits visible and the rest as subscript
  const significantDecimals = decimalPart.slice(0, subscriptStart);
  const subscriptDecimals = decimalPart.slice(
    subscriptStart,
    subscriptStart + 2,
  );

  return `${integerPart}.${significantDecimals}<sub>${subscriptDecimals}</sub>`;
}

export function ConvertToCustomSubscriptFormat(num: number | string) {
  num = parseFloat(num.toString());
  // const numString = num.toString();
  let numString = num.toFixed(20).replace(/\.?0+$/, ""); // Ensures we have enough decimal places and removes trailing zeros
  const parts = numString.split(".");

  if (parts.length !== 2) {
    return num;
    throw new Error("The number does not have a decimal part.");
  }

  const integerPart = parts[0];
  const decimalPart = parts[1];

  // Find the number of leading zeros
  const leadingZeros = decimalPart.match(/^0+/);
  const zeroCount = leadingZeros ? leadingZeros[0].length : 0;

  if (zeroCount <= 2) {
    return num.toFixed(3); // No subscript, return the number rounded to 3 significant digits
  }

  // Convert zero count to subscript characters
  const subscriptZeroCount = zeroCount
    .toString()
    .split("")
    .map((digit) => String.fromCharCode(8320 + parseInt(digit)))
    .join("");
  const significantDigits = decimalPart.slice(zeroCount, zeroCount + 4); // Limit to 3 significant digits
  const formattedString = `${integerPart}.0${subscriptZeroCount}${significantDigits}`;

  return formattedString;
}
export const getTokenValueInUsd = (
  token: tokenType,
  holding: string | number,
) => {
  // token.meta?.priceUsd * token.holding
  const priceInUsd = parseFloat(token.meta?.priceUsd ?? "0");
  const myHolding = parseFloat(holding.toString());
  const tokenVal = (priceInUsd * myHolding).toFixed(4);
  return tokenVal;
};

export const handleCommentLike = (
  isLike: boolean,
  comment: commentType | commentReplyType,
) => {
  let updatedComment = JSON.parse(JSON.stringify(comment));
  updatedComment["isLiked"] = isLike;
  updatedComment["likesCount"] = isLike
    ? updatedComment["likesCount"] + 1
    : updatedComment["likesCount"] - 1;
  return updatedComment;
};
export function formatNumberWithLetters(
  number: string | number | undefined,
  digits = 2,
  isPrecision = false,
) {
  const num = parseFloat(number?.toString() ?? "0");
  if (!num) return 0;
  //39000000000,00,000
  if (num >= 1e9) {
    return (num / 1e9).toFixed(digits) + "B"; // Billion
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(digits) + "M"; // Million
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(digits) + "K"; // Thousand
  } else {
    return isPrecision ? num.toPrecision(digits) : num.toFixed(digits); // Less than 1000
  }
}

export function parseJwt(token: string) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );
    const tokenData = JSON.parse(jsonPayload);
    if (tokenData.exp < Date.now() / 1000) {
      return null;
    }
    return tokenData;
  } catch (error) {
    return null;
  }
}

export function getImageUrl(path: string | object | undefined) {
  if (!path) {
    return null;
  }
  if (typeof path === "object") {
    return URL.createObjectURL(path as Blob);
  }
  return process.env["NEXT_PUBLIC_MEDIA_URL"] + path;
}

export function getErrorMessage(error: AxiosError) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (typeof error.response.data === "string") {
      return error.response.data;
    }
    if (
      Array.isArray(error.response.data.message) &&
      error.response.data.message.length > 0
    ) {
      return error.response.data.message[0];
    }
    return error.response.data.message;
  } else if (error.request) {
    // The request was made but no response was received
    return "Network Error";
  } else {
    // Something happened in setting up the request that triggered an Error
    return error.message;
  }
}

export function formatNumberWithCommas(
  number: number | string,
  decimals: number = 0,
) {
  let numStr = number.toString();
  let lastThree = numStr.slice(-3);
  let otherNumbers = numStr.slice(0, -3);
  if (otherNumbers !== "") {
    lastThree = "," + lastThree;
  }
  let fomattedNum =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return fomattedNum;
}

export function formatDate(
  date: string | undefined,
  showTime = false,
  showYear = true,
) {
  if (!date) {
    return "";
  }
  const myDate = new Date(date);
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
  };
  let options: object = {
    day: "numeric",
    month: "short",
  };
  if (showYear) {
    options = { ...options, year: "numeric" };
  }
  return myDate.toLocaleDateString(
    "en-US",
    showTime ? { ...timeOptions, ...options } : options,
  );
}

export function openExternalUrl(url?: string) {
  if (!url) return;
  window.open(url, "_blank");
}

export function formatDescription(str: any, length = 50) {
  if (typeof str === "string") {
    return str.length > length ? str.slice(0, length - 3) + "..." : str;
  }
  return "";
}

export function copyUrlToClipboard(content?: string) {
  const url = window.location.href;
  navigator.clipboard.writeText(content ? content : url).then(() => {
    // Optional: Provide feedback to the user
    toast.success(
      content ? "Copied to clipboard!" : "Shareable Link Copied to clipboard!",
    );
  });
}

export function secondsFromNow(dateString: string, format = "humanReadable") {
  // Parse the input date string into a Date object
  const inputDate = new Date(dateString);

  // Handle invalid date input
  if (isNaN(inputDate.getTime())) {
    return "Invalid date format";
  }

  // Get the current time
  const now = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = now.getTime() - inputDate.getTime();

  // Convert milliseconds to seconds
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

  // Format the output based on the provided format
  if (format === "humanReadable") {
    const days = Math.floor(differenceInSeconds / (60 * 60 * 24));
    const hours = Math.floor(
      (differenceInSeconds % (60 * 60 * 24)) / (60 * 60),
    );
    const minutes = Math.floor((differenceInSeconds % (60 * 60)) / 60);
    const seconds = differenceInSeconds % 60;

    const difference = `${days > 0 ? days + " days" : ""} ${days === 0 && hours > 0 ? hours + "h" : ""} ${days === 0 && hours === 0 && minutes > 0 ? minutes + "min " : ""} ${days === 0 && hours === 0 && minutes === 0 && seconds > 0 ? seconds + "sec" : ""}`;
    return difference.trim().length === 0 ? "Now" : difference;
  } else {
    return differenceInSeconds;
  }
}

export const validateUserName = (username: string) => {
  // Rule 1: Username must be between 3 and 20 characters long
  if (username.length < 3 || username.length > 20) {
    return "Username must be between 3 and 20 characters long.";
  }

  // Rule 2: Username can only contain letters, numbers, and underscores
  const validChars = /^[a-zA-Z0-9_]+$/;
  if (!validChars.test(username)) {
    return "Username can only contain letters, numbers, and underscores.";
  }

  return "";
};
export const validateTicker = (ticker: string) => {
  // Rule 1: Username must be between 3 and 20 characters long
  if (ticker === "" || !ticker) {
    return "Ticker is required";
  }
  if (ticker.length < 3 || ticker.length > 20) {
    return "Ticker must be between 3 and 10 characters long.";
  }

  // Rule 2: Username can only contain lowercase letters, numbers, and underscores
  const validChars = /^[a-zA-Z0-9]+$/;
  if (!validChars.test(ticker)) {
    return "Ticker can only contain letters and numbers.";
  }

  return "";
};

export function validateURL(url: string) {
  const pattern = new RegExp(
    "^https?:\\/\\/" + // Protocol (http or https)
      "((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IPv4 address
      "(\\:\\d+)?" + // Optional port
      "(\\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?" + // Path
      "(\\?[;&a-zA-Z0-9%_+.~#?&//=]*)?" + // Query string
      "(\\#[-a-zA-Z0-9@:%_+.~#?&//=]*)?$", // Fragment locator
    "i", // Case-insensitive
  );

  return pattern.test(url);
}

export function validateURLWithoutHttp(url: string) {
  const pattern = /^(?:\w+:\/\/)?(?:[\w-]+\.)+[a-z]{2,6}(?:\/\S*)?$/i;
  return pattern.test(url);
}
