import { createSlice } from "@reduxjs/toolkit";
import { userType } from "@/types/global";

export interface initLoginState {
  token?: string;
  chart?: "fomo" | "uniswap";
}

const initLoginState: initLoginState = {
  token: undefined,
  chart: undefined,
};
const chartReducer = createSlice({
  name: "Chart",
  initialState: initLoginState,
  reducers: {
    setTokenMint: (loginState, action) => {
      const token = action.payload;
      return {
        ...loginState,
        token,
      };
    },
    setChartType: (loginState, action) => {
      const chart = action.payload;
      return {
        ...loginState,
        chart,
      };
    },
    resetChart: (loginState) => {
      return initLoginState;
    },
  },
});

export const { setTokenMint, setChartType, resetChart } = chartReducer.actions;
// export default reducer;
export default chartReducer.reducer;
