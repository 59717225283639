import { createSlice } from "@reduxjs/toolkit";
import { shareDataTye, tokenType, userType } from "@/types/global";

export interface initLoginState {
  CreateCoinPopUp: boolean;
  HowItWorks: boolean;
  Report: boolean;
  TodaysMeme: boolean;
  Comments: boolean;
  Profile: boolean;
  Share: boolean;
  config?: tokenType | userType | shareDataTye;
}

const initLoginState: initLoginState = {
  CreateCoinPopUp: false,
  HowItWorks: false,
  Comments: false,
  Report: false,
  Share: false,
  TodaysMeme: false,
  Profile: false,
};
const popUpsReducer = createSlice({
  name: "PopUp",
  initialState: initLoginState,
  reducers: {
    showPopUp: (loginState, action) => {
      const popup = action.payload;
      return {
        ...loginState,
        [popup]: true,
      };
    },
    hidePopUp: (loginState, action) => {
      const popup = action.payload;
      return {
        ...loginState,
        [popup]: false,
        config: undefined,
      };
    },
    setPopUpData: (loginState, action) => {
      const popup = action.payload;
      return {
        ...loginState,
        config: popup,
      };
    },
  },
});

export const { showPopUp, hidePopUp, setPopUpData } = popUpsReducer.actions;
// export default reducer;
export default popUpsReducer.reducer;
