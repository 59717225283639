import { createSlice } from "@reduxjs/toolkit";

export interface initLoginState {
  token: number;
  user: number;
}

const initLoginState: initLoginState = {
  token: 0,
  user: 0,
};
const refreshReducer = createSlice({
  name: "refresh",
  initialState: initLoginState,
  reducers: {
    refreshTokenData: (loginState) => {
      return {
        ...loginState,
        token: loginState.token + 1,
      };
    },
    refreshUserData: (loginState) => {
      return {
        ...loginState,
        user: loginState.user + 1,
      };
    },
  },
});

export const { refreshTokenData, refreshUserData } = refreshReducer.actions;
// export default reducer;
export default refreshReducer.reducer;
